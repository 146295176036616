import moment from 'moment'
import { version } from '../../package.json'
import AssetRepository from '@/repositories/AssetRepository'
import FileRepository from '@/repositories/FileRepository'
import axios from '@/plugins/axios'
import { setI18nLocalLanguage } from '@/plugins/i18n'

export default {
    data: () => ({
        drawer: true,
        is_online: navigator.onLine,
        role_admin: 4,
        role_all_access: 3,
        role_edit: 2,
        role_vision: 1,
        role_no_access: 0,
        app_version: version,
        application_ready: false,
        //Fake async method
        event_finally: new Event('finally')
    }),
    computed: {
        is_mobile() {
            let isMobile = false
            if (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs) {
                isMobile = true
                this.drawer = false
            } else {
                this.drawer = true
            }

            return isMobile
        },
        language() {
            const language =
                window.localStorage.getItem('use_navigator_language') === 'true'
                    ? navigator.language
                    : this.$store.getters['user_connected/user_connected'].language

            return this.parseLanguage(language, false)
        },
        date_format() {
            return this.getFormatDateBaseOnLanguage().dateFormat
        },
        date_format_date_picker() {
            return this.getFormatDateBaseOnLanguage().dateFormatDatePicker
        },
        date_format_chart() {
            return this.getFormatDateBaseOnLanguage().dateFormatChart
        },
        date_format_csv() {
            return this.getFormatDateBaseOnLanguage().dateFormatCsv
        },
        page_name_equipment() {
            if (
                this.$store.getters['asset_types/asset_types_which_have_asset'].length +
                this.$store.getters['asset_module/asset_modules'].length >
                1 ||
                this.$store.getters['asset_types/asset_types_which_have_asset'].length +
                this.$store.getters['asset_module/asset_modules'].length ===
                0
            ) {
                return this.$t('left_navbar_component.assets')
            }

            return this.$store.getters['asset_types/asset_types_which_have_asset'][0]
                ? this.$t(`equipment_page.${this.$store.getters['asset_types/asset_types_which_have_asset'][0].key}`)
                : this.$t(`modules.${this.$store.getters['asset_module/asset_modules'][0].designation}`)
        }
    },
    watch: {
        is_online() {
            if (!this.is_online) {
                this.showSnackbar('error', 'Aucune connexion internet.')
            }
        }
    },
    methods: {
        parseLanguage(language, minimised) {
            if (language.includes('en')) {
                return minimised ? 'gb' : 'en-EN'
            } else if (language.includes('de')) {
                return minimised ? 'de' : 'de-DE'
            } else if (language.includes('es')) {
                return minimised ? 'es' : 'es-ES'
            } else if (language.includes('it')) {
                return minimised ? 'it' : 'it-IT'
            } else if (language.includes('nl')) {
                return minimised ? 'nl' : 'nl-NL'
            } else if (language.includes('pt')) {
                return minimised ? 'pt' : 'pt-PT'
            }

            return minimised ? 'fr' : 'fr-FR'
        },

        parseLanguageToCountryName(language) {
            if (language === 'default') {
                return this.parseLanguage(navigator.language)
            }

            if (language.includes('de')) {
                return 'Deutsch'
            } else if (language.includes('es')) {
                return 'Español'
            } else if (language.includes('it')) {
                return 'Italiano'
            } else if (language.includes('nl')) {
                return 'Nederlands'
            } else if (language.includes('pt')) {
                return 'Português'
            } else if (language.includes('en')) {
                return 'English'
            }

            return 'Français'
        },

        navigateTo(url) {
            this.$router.push({ name: url })
        },

        userHasAccess(designation, levelPermission, redirection = false) {
            let hasAccess = false
            let permission = this.$store.getters['user_connected/getPermissionByDesignation'](designation)
            if (permission) {
                if (permission.level_permission >= levelPermission) {
                    hasAccess = true
                }
            }
            if (redirection && !hasAccess) {
                this.logout()
            }
            return hasAccess
        },

        async postElasticSearchLogFront(error) {
            let ip_client = 'unknown'
            await axios
                .get('https://api.ipify.org?format=json', {
                    transformRequest: (data, headers) => {
                        delete headers['Authorization']
                    }
                })
                .then((success) => {
                    ip_client = success.data.ip
                })

            const token = window.sessionStorage.getItem('auth_token_default')
            await axios.post(
                `${process.env.VUE_APP_API_URL}/api/v1/elastic_search/log`,
                {
                    error: error.message ?? 'empty',
                    message: error.stack ?? 'empty',
                    ip_client: ip_client
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            )
        },

        manageError(error) {
            if (!error.response) {
                //If is it an error js
                this.postElasticSearchLogFront(error)
            }

            if (!this.is_online) {
                this.showSnackbar('error', this.$t('error_display.no_internet'))
            } else if (error.response.status === 403) {
                this.showSnackbar('error', this.$t('error_display.authorization_required'))
                this.$auth.logout({})
            } else if (error.response.data.status === 498) {
                this.showSnackbar('error', this.$t('error_display.expired_token'))
                this.$auth.logout({})
            } else {
                let type = 'error'
                let text = this.$t('error_display.error_global')

                if (error.response !== undefined) {
                    text = this.$t('error_display.error_occurred')

                    if (this.$te(`error_display.${error.response.data.translation_key}`)) {
                        text = this.$t(`error_display.${error.response.data.translation_key}`)
                    }
                }

                this.showSnackbar(type, text)
            }
        },

        showSnackbar(type, text, url = null) {
            this.$store.dispatch('snackbar/showSnackbar', { snackbar_type: type, snackbar_text: text, snackbar_url: url})
        },

        numberWithSpaces(value, toFixed = 2) {
            let result = value
            if (value) {
                result = parseFloat(result)
                    .toFixed(toFixed)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            }
            return result
        },

        moment(date) {
            moment.locale(this.language)
            return moment(date)
        },

        logout() {
            this.$store.dispatch('general/setIdProfessional', null)
            this.$auth.logout({})
        },

        updateOrCreatePageOrderConfiguration(order_configuration_equipment, message) {
            if (this.$store.getters['page_order_configuration/page_order_configuration_equipment'].id_page_order) {
                this.$store
                    .dispatch('page_order_configuration/updatePageOrderConfiguration', {
                        id_page_order_configuration:
                        this.$store.getters['page_order_configuration/page_order_configuration_equipment'].id_page_order,
                        order_configuration: order_configuration_equipment
                    })
                    .then(() => {
                        if (message) {
                            this.showSnackbar('success', message)
                        }
                    })
                    .catch((error) => {
                        this.manageError(error)
                        if (error.response.status === 404) {
                            this.getPageOrderConfiguration()
                        }
                    })
                    .finally(() => {
                        document.dispatchEvent(this.event_finally)
                    })
            } else {
                this.$store
                    .dispatch('page_order_configuration/createPageOrderConfiguration', {
                        page: 'equipment',
                        order_configuration: order_configuration_equipment
                    })
                    .then(() => {
                        if (message) {
                            this.showSnackbar('success', message)
                        }
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
                    .finally(() => {
                        document.dispatchEvent(this.event_finally)
                    })
            }
        },

        calculateNbNotificationsByModule(module) {
            let totalNotification = 0
            if (module === 'orders_cockpit') {
                totalNotification = this.$store.getters['orders_cockpit/orders_cockpit_pending_validation'].length
            }
            return totalNotification
        },

        getFormatDateBaseOnLanguage() {
            let dateFormat = ''
            let dateFormatChart = ''
            let dateFormatDatePicker = ''
            let dateFormatCsv = ''

            switch (this.language) {
                case 'en-GB':
                    dateFormat = 'YYYY/MM/D'
                    dateFormatChart = '%Y/%m/%d %H:%M:%S'
                    dateFormatDatePicker = 'DD MMMM YYYY'
                    dateFormatCsv = 'YYYY/MM/D HH:mm:ss'
                    break
                case 'de-DE':
                    dateFormat = 'YYYY/MM/D'
                    dateFormatChart = '%Y/%m/%d %H:%M:%S'
                    dateFormatDatePicker = 'DD. MMMM YYYY'
                    dateFormatCsv = 'YYYY/MM/D HH:mm:ss'
                    break
                case 'fr-FR':
                default:
                    dateFormat = 'DD/MM/Y'
                    dateFormatChart = '%d/%m/%Y %H:%M:%S'
                    dateFormatDatePicker = 'DD MMMM YYYY'
                    dateFormatCsv = 'DD/MM/Y HH:mm:ss'
                    break
            }

            return {
                dateFormat: dateFormat,
                dateFormatChart: dateFormatChart,
                dateFormatDatePicker: dateFormatDatePicker,
                dateFormatCsv: dateFormatCsv
            }
        },

        setAllInformationAfterConnect(id_professional, user) {
            if (this.$router.currentRoute.name !== 'equipment') {
                this.$router.push({ name: 'equipment' })
            }

            setI18nLocalLanguage(window.localStorage.getItem('use_navigator_language') === 'true' ? navigator.language : user.language)

            this.$store.dispatch('user_connected/setAuthenticateUser', user)
            this.$store.dispatch('general/setGlobalLoader', true)
            this.$store.dispatch('general/setIdProfessional', id_professional)

            this.getClientsOfUser()
            this.getNavbarConfiguration()
            this.getPageOrderConfiguration()
            this.getProfessionalAssetTypes()
            this.getUserClientDomiciles()
            this.getNotifications()
            this.getDisplayOption(id_professional)

            if (this.userHasAccess('ORDC', this.role_vision)) {
                this.getOrdersCockpitPendingValidation()
            }

            this.$store.dispatch('asset_module/cleanAssetModuleStore')
            this.$store.dispatch('asset_module/fetchAssetModules')
        },

        getDisplayOption(id_professional) {
            this.$store
                .dispatch('display_option/getDisplayOptionByIdProfessional', id_professional)
                .then(() => {
                    if (this.$store.getters['general/application_version'] === this.app_version) {
                        this.application_ready = true
                    }
                    this.setDisplayOption()
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        setDisplayOption() {
            let displayOption = this.$store.getters['display_option/display_option']
            this.$vuetify.theme.themes.light.primary = this.$vuetify.theme.themes.dark.primary = displayOption.primary
            this.$vuetify.theme.themes.light.primary_dark = this.$vuetify.theme.themes.dark.primary_dark = displayOption.primary_dark
            this.$vuetify.theme.themes.light.primary_light = this.$vuetify.theme.themes.dark.primary_light = displayOption.primary_light
            this.$vuetify.theme.themes.light.secondary = this.$vuetify.theme.themes.dark.secondary = displayOption.secondary
            this.$vuetify.theme.themes.light.secondary_dark = this.$vuetify.theme.themes.dark.secondary_dark = displayOption.secondary_dark
            this.$vuetify.theme.themes.light.secondary_light = this.$vuetify.theme.themes.dark.secondary_light =
                displayOption.secondary_light
            this.$vuetify.theme.themes.light.error = this.$vuetify.theme.themes.dark.error = displayOption.error
            this.$vuetify.theme.themes.light.warning = this.$vuetify.theme.themes.dark.warning = displayOption.warning
            this.$vuetify.theme.themes.light.success = this.$vuetify.theme.themes.dark.success = displayOption.success
            document.getElementById('favicon').href = displayOption.src_favicon

            if (this.application_ready || process.env.VUE_APP_NODE_ENV === 'LOCAL') {
                this.$store.dispatch('general/setGlobalLoader', false)
            }
        },

        getNavbarConfiguration() {
            this.$store.dispatch('user_navbar_configuration/getNavbarConfigurations').catch((error) => {
                this.manageError(error)
            })
        },

        getUserClientDomiciles() {
            this.$store
                .dispatch('domiciles/getUserClientDomiciles', this.$store.getters['user_connected/user_connected'].id_user)
                .catch((error) => {
                    this.manageError(error)
                })
        },

        getPageOrderConfiguration() {
            this.$store.dispatch('page_order_configuration/getPageOrderConfigurationEquipment').catch((error) => {
                this.manageError(error)
            })
        },

        getClientsOfUser() {
            this.$store
                .dispatch('clients/getClientsOfUser', this.$store.getters['user_connected/user_connected'].id_user)
                .catch((error) => {
                    this.manageError(error)
                })
        },

        getOrdersCockpitPendingValidation() {
            this.$store.dispatch('orders_cockpit/getOrdersCockpitPendingValidation').catch((error) => {
                this.manageError(error)
            })
        },

        getNotifications() {
            this.$store
                .dispatch('notifications/getNotifications', this.$store.getters['user_connected/user_connected'].id_client)
                .catch((error) => {
                    this.manageError(error)
                })
        },

        getProfessionalAssetTypes() {
            this.$store
                .dispatch('asset_types/getProfessionalAssetTypes', this.$store.getters['user_connected/user_connected'].id_professional)
                .then(() => {
                    this.getAssetByAssetTypeAndClient()
                })
                .catch((error) => {
                    if (error.response.data.translation_key === '') {
                        error.response.data.translation_key = 'retrieving_equipment_error'
                    }
                    this.manageError(error)
                })
        },

        getAssetByAssetTypeAndClient() {
            this.$store.dispatch('asset_types/resetAssetTypeWithAsset')

            this.$store.getters['asset_types/asset_types'].forEach((asset_type) => {
                //TODO: Fix error on referentiel for generic asset_type
                if (asset_type.id_referential !== 292) {
                    AssetRepository.getAssetByAssetTypeAndClient(
                        this.$store.getters['user_connected/user_connected'].id_client,
                        asset_type.id_referential
                    )
                        .then((success) => {
                            if (success.data.data.length > 0) {
                                this.$store.dispatch('asset_types/setAssetTypesWhichHaveAsset', asset_type)
                            }
                        })
                        .catch((error) => {
                            this.manageError(error)
                        })
                }
            })
        },

        setDateForRequest(date) {
            return this.moment(date).format('YYYY-MM-DD')
        },

        getFormattedAddress(address) {
            return address.address + ' ' + address.postal_code + ' ' + address.city + ' ' + address.country
        },

        compareDate(start, end = this.moment()) {
            /* -1 older // 0 equal // 1 younger */
            let result = moment(start).toDate() === moment(end).toDate() ? 0 : -1
            if (moment(start).toDate() > moment(end).toDate()) {
                result = 1
            }
            return result
        },

        downloadCsvFile(csvData, title) {
            const csvBlob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvData.join('\n')], {
                type: 'text/csv',
                name: title
            })
            let anchorElement = window.document.createElement('a')
            anchorElement.href = URL.createObjectURL(csvBlob)
            anchorElement.download = title + '_' + this.moment().format(this.date_format) + '.csv'
            document.body.appendChild(anchorElement)
            anchorElement.click()
        },

        downloadFile(file, moduleDesignation) {
            FileRepository.getByIdFileAndModuleDesignation(file.id_file, moduleDesignation)
                .then((success) => {
                    const link = document.createElement('a')
                    link.href = URL.createObjectURL(new Blob([success.data], { type: file.file_type.type }))
                    link.download = file.filename + file.file_type.extension
                    document.body.appendChild(link)
                    link.click()
                })
                .catch((error) => {
                    this.manageError(error)
                })
        },

        getTunnelInstallationUrl() {
            let emailUserConnected = this.$store.getters['user_connected/user_connected'].email
            let endUrlInstallation = `/installation?from_sens&email=${emailUserConnected}`

            switch (process.env.VUE_APP_API_URL) {
                case 'https://api-pwa.quatre-factorielle.com':
                    return `https://installation.fourdata.io${endUrlInstallation}`
                case 'https://prp.api.quatre-factorielle.com':
                    return `https://prp.installation.fourdata.io${endUrlInstallation}`
                default:
                    return `http://localhost:8080${endUrlInstallation}`
            }
        },

        isDeviceGeolocalisation(device) {
            return (
                device.device_referentiel.id_article === 30 ||
                device.device_referentiel.id_device_referentiel === 87 ||
                device.device_referentiel.id_device_referentiel === 108 ||
                device.device_referentiel.id_device_referentiel === 209
            )
        }
    },
    mounted() {
        window.addEventListener('online', () => {
            this.is_online = navigator.onLine
        })
        window.addEventListener('offline', () => {
            this.is_online = navigator.onLine
        })
    }
}
