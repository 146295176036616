import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IDisplayOption } from '@/models/IDisplayOption'

const resource = 'api/v1/display_option'

export default {
    getDisplayOptionByPwaSensUrl(): Promise<AxiosResponse<IServerResponse<IDisplayOption>>> {
        return axios.get(`${resource}/pwa_sens`)
    },

    getDisplayOptionByIdProfessional(id_professional: number): Promise<AxiosResponse<IServerResponse<IDisplayOption>>> {
        return axios.get(`${resource}/professional/${id_professional}`)
    },
}
