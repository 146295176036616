<template>
    <div id="profile-picture-component">
        <v-badge
            :value="verify_certified && user.date_certified_mail === null"
            class="badge-lower-size"
            color="error"
            content="!"
            offset-x="14"
            offset-y="14"
        >
            <div :style="`font-size: ${width}px`">
                <div id="profile-picture-component" :style="`background: ${user.badge_color}`" class="profile-picture-circle">
                    <span
                        :style="`font-size: ${initial.length >= 3 ? '.4' : '.5'}em; color: ${
                            lightOrDark(user.badge_color) === 'light' ? 'black' : 'white'
                        } !important;`"
                        class="text-uppercase"
                    >
                        {{ initial }}
                    </span>
                </div>
            </div>
        </v-badge>
    </div>
</template>

<script>
export default {
    name: 'ProfilePictureComponent',
    props: {
        verify_certified: {
            type: Boolean,
            required: false,
            default: false,
        },
        user: {
            type: Object,
            required: true,
        },
        width: {
            type: Number,
            required: false,
            default: 54,
        },
    },
    computed: {
        initial() {
            return this.user.initial
                ? this.user.initial
                : isNaN(this.user.first_name?.charAt(0) + this.user.last_name?.charAt(0))
                ? ''
                : this.user.first_name?.charAt(0) + this.user.last_name?.charAt(0)
        },
    },
    methods: {
        lightOrDark(color = '') {
            // Variables for red, green, blue values
            let r, g, b, hsp
            // Check the format of the color, HEX or RGB?
            if (color.match(/^rgb/)) {
                // If HEX --> store the red, green, blue values in separate variables
                color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
                r = color[1]
                g = color[2]
                b = color[3]
            } else {
                // If RGB --> Convert it to HEX: http://gist.github.com/983661
                color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'))
                r = color >> 16
                g = (color >> 8) & 255
                b = color & 255
            }
            // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
            hsp = 0.299 * r + 0.587 * g + 0.114 * b

            // Using the HSP value, determine whether the color is light or dark
            if (hsp > 127.5) {
                return 'light'
            } else {
                return 'dark'
            }
        },
    },
}
</script>

<style>
.profile-picture-circle {
    width: 1em;
    height: 1em;
    border-radius: 100%;
    background: #38cda2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: background-color 0.2s ease;
}

.profile-picture-circle > span {
    transition: color 0.2s ease;
}
</style>
