<template>
    <div id="app">
        <HiddenInputNotifications />
        <v-app
            v-if="
                $store.getters['general/come_from_sens'] ||
                (!$store.getters['general/come_from_sens'] && !$store.getters['general/show_global_loader'])
            "
            class="global"
        >
            <left-navbar-component v-if="$auth.check()" />
            <v-main :class="$auth.user() ? 'background-color-main' : ''">

                <pwa-sens-banner-module-alert-component/>


                <router-view />
            </v-main>

            <bottom-navbar-component v-if="$auth.check()" />

            <snackbar-component />
        </v-app>

        <div v-if="$store.getters['general/show_global_loader']" class="global-loader">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import './scss/app.scss'
import LeftNavbarComponent from '@/components/Navigation/LeftNavbarComponent.vue'
import BottomNavbarComponent from '@/components/Navigation/BottomNavbarComponent.vue'
import SnackbarComponent from '@/components/Global/SnackbarComponent.vue'
import HiddenInputNotifications from '@/components/HiddenInput/Notifications.vue'
import { setI18nLocalLanguage } from '@/plugins/i18n'
import PwaSensBannerModuleAlertComponent from '@/components/Global/PwaSensBannerModuleAlertComponent.vue'

export default Vue.extend({
    name: 'App',
    components: { PwaSensBannerModuleAlertComponent, SnackbarComponent, BottomNavbarComponent, LeftNavbarComponent, HiddenInputNotifications },
    data() {
        return {
            registration: null,
        }
    },
    created() {
        this.$store.dispatch('general/setGlobalLoader', true);
        if (this.$store.getters['general/application_version'] === this.app_version) {
            this.application_ready = true;
        } else if(this.$store.getters['general/application_version'] === '') {
            this.application_ready = true;
            this.$store.dispatch('general/setApplicationVersion', this.app_version)
        } else {
            this.$store.dispatch('general/setApplicationVersion', this.app_version);
        }

        document.addEventListener('serviceWorkerUpdateEvent', this.updateAvailable, {once: true})
        try {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                window.location.reload()
            });
        } catch(error) {
            this.$store.dispatch('general/setGlobalLoader', false);
        }

        this.$auth
            .load()
            .then(async () => {
                //Avoid refreshes when using a new login token
                if (!window.location.href.includes('token') && this.$auth.check()) {
                    //Refresh token /auth/refresh
                    await this.$auth.refresh({}).then((success) => {
                        //Set token
                        this.$auth.token('auth_token_default', success.data.data)
                        //Fetch user information /me
                        this.$auth.fetch({}).then(async (result) => {
                            this.$store.dispatch('user_connected/setAuthenticateUser', result.data.data)
                            const language = window.localStorage.getItem('use_navigator_language') === 'true'
                                ? navigator.language
                                : result.data.data.language
                            setI18nLocalLanguage(language)
                            this.$vuetify.lang.current = this.parseLanguage(language, true)
                            await this.$store.dispatch('asset_module/fetchAssetModules')
                        })
                    }).catch(()  => {
                        //Catch for 403 on token refresh
                        window.localStorage.clear();
                        this.$router.push('login')
                    })
                }

                //Check if application is install or on browser
                if (!this.$auth.user() && !window.matchMedia('(display-mode: standalone)').matches) {
                    this.getDisplayOptionByPwaSensUrl()
                } else {
                    this.setDisplayOption();
                }
            });

        document.addEventListener(
            'tokenExpired',
            () => {
                this.showSnackbar('error', this.$t('error_display.expired_token'))
                this.$auth.logout({})
            },
            { once: true }
        )
    },
    methods: {
        updateAvailable(event) {
            this.registration = event.detail
            if (this.registration || this.registration.waiting) {
                this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
            }
        },

        getDisplayOptionByPwaSensUrl() {
            this.$store
                .dispatch('display_option/getDisplayOptionByPwaSensUrl')
                .then(() => {
                    this.setDisplayOption()
                })
                .catch((error) => {
                    if (error.response && error.response.status === 404) {
                        this.$store.dispatch('snackbar/showSnackbar', {
                            snackbar_type: 'warning',
                            snackbar_text: this.$t(`error_display.${error.response.data.translation_key}`),
                        })
                    } else {
                        this.$store.dispatch('snackbar/showSnackbar', {
                            snackbar_type: 'error',
                            snackbar_text: this.$t('error_display.display_option_error'),
                        })
                    }
                });
        },
    },
})
</script>

<style>
@font-face {
    font-family: 'Sarabun';
    src: local('Sarabun'), url(/fonts/Sarabun/Sarabun-Regular.ttf) format('truetype');
}

.global-loader {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffff;
    top: 0;
    left: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.loader:after {
    content: ' ';
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    border-radius: 50%;
    border: 5px black solid;
    border-color: black transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
    animation: lds-dual-ring 1.2s linear infinite;
}

@-webkit-keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>