import DeviceRepository from '@/repositories/DeviceRepository'
import Vue from 'vue'
import type { IAlert } from '@/models/IAlert'
import type { IAssetCompleteInformation } from '@/models/IAssetCompleteInformation'

const state = () => ({
    alerts: Object.create(null), //Map <key: id_device, value: alert[]>
})

const mutations = {
    setAlertsByDevice(state, payload: { id_device: number; alerts: IAlert[] }): void {
        Vue.set(state.alerts, payload.id_device, payload.alerts)
    },
}

const actions = {
    async fetchAlertsByAsset({ commit }, asset: IAssetCompleteInformation): Promise<void> {
        await Promise.all(
            asset.devices.map((device) => {
                return Promise.resolve(
                    DeviceRepository.getAllAlertByIdDevice(device.id_device).then((success) => {
                        commit('setAlertsByDevice', { id_device: device.id_device, alerts: success.data.data })
                    })
                )
            })
        )
    },
}

const getters = {
    alerts: (state): IAlert[] => state.alerts,
    alertsByDevice:
        (state) =>
        (id_device: number): IAlert[] =>
            state.alerts ? state.alerts[id_device] : [],
    alertsByAsset:
        (state) =>
        (asset: IAssetCompleteInformation): IAlert[] => {
            const alerts : IAlert[] = []
            asset.devices.forEach((device) => {
                state.alerts[device.id_device]?.forEach((alert) => {
                    alerts.push(alert)
                })
            })
            return alerts
        },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
