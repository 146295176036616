import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'

const resource = 'api/v1/user_invitation'

export default {
    postInviteUser(idClient: number, data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/invite/client/${idClient}`, data)
    },

    postResendInvitationUser(idInvitation: number): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/resend_mail/${idInvitation}`)
    },

    putAcceptInvitation(token: string): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/accept/${token}`)
    },

    putDeclineInvitation(token: string): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/decline/${token}`)
    },

    putRoleInvitation(idInvitation: number, idRole: number): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/${idInvitation}/role`, { id_role: idRole })
    },

    deleteUserInvitation(data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.delete(`${resource}`, { data: data })
    },
}
