import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IClient } from '@/models/IClient'
import type { IUserInvitation } from '@/models/IUserInvitation'
import type { IUser } from '@/models/IUser'
import type { IUserAuthenticate } from '@/models/IUserAuthenticate'

const resource = 'api/v1/user'

export default {
    postSendEmailResetPassword(data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/invitation/reset_password`, data)
    },

    postCheckIfUserCanResetPassword(data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/can_reset_password`, data)
    },

    postResetPassword(data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/reset_password`, data)
    },

    getAuthenticateUser(): Promise<AxiosResponse<IServerResponse<IUserAuthenticate>>> {
        return axios.get(`/auth/me`)
    },

    getClientsOfUser(idUser: number): Promise<AxiosResponse<IServerResponse<IClient[]>>> {
        return axios.get(`${resource}/${idUser}/clients`)
    },

    getSwitchClientAsUserClient(idUser: number, idClient: number): Promise<AxiosResponse<IServerResponse<string>>> {
        return axios.get(`${resource}/${idUser}/switch/client/${idClient}`)
    },

    getUserInvitation(idUser: string): Promise<AxiosResponse<IServerResponse<IUserInvitation>>> {
        return axios.get(`${resource}/${idUser}/user_invitations`)
    },

    updateUserProfile(user: IUser): Promise<AxiosResponse<IServerResponse<IUser>>> {
        return axios.put(`${resource}`, user)
    },

    updateUserPassword(
        oldPassword: string,
        newPassword: string,
        newConfirmPassword: string
    ): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/password`, {
            old_password: oldPassword,
            new_password: newPassword,
            new_confirm_password: newConfirmPassword,
        })
    },

    deleteUserAccess(idClient: number): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.delete(`${resource}/client/${idClient}/access`)
    },

    registerPushyToken(token: string, platform: string, type: string): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/register_push_token`, {
            token: token,
            type: type,
            platform: platform,
        })
    },

    platformDoesNotExists(url: string): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/unregistered_platform`, {
            url: url,
        })
    },

    resendValidationMail(): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/resend_validation_email`)
    },

    updateUserBadge(id_user: number, initial: string, badge_color: string): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/badge/${id_user}`, {
            initial: initial,
            badge_color: badge_color
        });
    },
}
