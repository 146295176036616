import Vue from 'vue'
import VueInternationalization, { LocaleMessages } from 'vue-i18n'

Vue.use(VueInternationalization)

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages: LocaleMessages = {}

    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)

        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })

    return messages
}

const options = {
    locale: getLocalLanguage(navigator.language),
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en-EN',
    messages: loadLocaleMessages(),
}

function getLocalLanguage(language: string): string | undefined
{
    if (language.includes('en')) {
        return 'en-EN'
    } else if (language.includes('de')) {
        return 'de-DE'
    } else if (language.includes('es')) {
        return 'es-ES'
    } else if (language.includes('it')) {
        return 'it-IT'
    } else if (language.includes('nl')) {
        return 'nl-NL'
    } else if (language.includes('pt')) {
        return 'pt-PT'
    } else if (language.includes('fr')) {
        return 'fr-FR'
    }
}

export function setI18nLocalLanguage(language: string): void
{
    i18n.locale = getLocalLanguage(language)!
}

export const i18n = new VueInternationalization(options)
