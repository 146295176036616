import UserConfigurationRepository from '@/repositories/UserConfigurationRepository'
import type { IUserNavbarConfiguration } from '@/models/IUserNavbarConfiguration'

const state = () => ({
    user_navbar_configurations: null,
})

const mutations = {
    setUserNavbarConfigurations(state, userNavBarConfigurations: IUserNavbarConfiguration): void {
        state.user_navbar_configurations = userNavBarConfigurations
    },
}

const actions = {
    async getNavbarConfigurations({ commit }): Promise<void> {
        await UserConfigurationRepository.getUserNavbarConfigurationByConnectedUser().then((success) => {
            commit('setUserNavbarConfigurations', success.data.data)
        })
    },

    async createUserNavbarConfigurations({ commit }, navbarConfiguration: IUserNavbarConfiguration[]): Promise<void> {
        await UserConfigurationRepository.postUserNavbarConfiguration(navbarConfiguration).then((success) => {
            commit('setUserNavbarConfigurations', success.data.data)
        })
    },

    async updateUserNavbarConfigurations({ commit }, payload): Promise<void> {
        await UserConfigurationRepository.updateUserNavbarConfiguration(payload.navbar_configuration).then((success) => {
            commit('setUserNavbarConfigurations', success.data.data)
        })
    },
}

const getters = {
    user_navbar_configurations: (state) => state.user_navbar_configurations,
    navbar_configurations: (state) => (state.user_navbar_configurations ? state.user_navbar_configurations.configuration : null),
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
