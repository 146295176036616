import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './plugins/router'
import store from './store/store'
import vuetify from './plugins/vuetify'
import { i18n } from './plugins/i18n'
import global from './plugins/global.js'
import auth from './plugins/auth'
import WebsanovaAuth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import NoDataToDisplay from 'highcharts/modules/no-data-to-display'
import Highcharts from 'highcharts'
import Stock from 'highcharts/modules/stock'
import Exporting from 'highcharts/modules/exporting'
import HighchartsVue from 'highcharts-vue'
import HighchartsRoundedCorner from "highcharts-rounded-corners";
import * as VueGoogleMaps from 'vue2-google-maps'
import Vue2TouchEvents from 'vue2-touch-events'
import Pushy from './plugins/pushy'
import VueGtag from 'vue-gtag'

Vue.use(
    VueGtag,
    {
        config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_KEY },
    },
    router
)

Vue.use(Vue2TouchEvents)

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_KEY,
        libraries: 'places',
    },
    installComponents: true,
})

NoDataToDisplay(Highcharts)
Vue.use(WebsanovaAuth, auth)

Stock(Highcharts)
Exporting(Highcharts)

HighchartsRoundedCorner(Highcharts)
Vue.use(HighchartsVue)
Highcharts.setOptions({
    lang: {
        months: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
        weekdays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        shortMonths: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
    },
})

Vue.config.productionTip = false
Vue.mixin(global)

const app = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
}).$mount('#app')

Vue.use(Pushy, {
    store: store,
})

//For Cypress test
declare global {
    interface Window {
        app: any
    }
}
window.app = app
