import ClientRepository from '@/repositories/ClientRepository';
import {IAssetCluster} from '@/models/IAssetCluster';

const state = () => ({
    asset_clusters: [],
})

const mutations = {
    setAssetClusters(state, asset_clusters: IAssetCluster[]): void {
        state.asset_clusters = asset_clusters
    },
}

const actions = {
    async getClientAssetClusters({ commit }, payload): Promise<void> {
        await ClientRepository.getClientAssetClusters(payload.id_client).then((success) => {
            commit('setAssetClusters', success.data.data)
        });
    },
}

const getters = {
    asset_clusters: (state) => state.asset_clusters,
    getAssetClusterById: (state) => (idAssetCluster) => state.asset_clusters ? state.asset_clusters.find((asset_cluster) => asset_cluster.id_asset_cluster === parseInt(idAssetCluster)) : null,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
