import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IOrderConfiguration, IPageOrderConfiguration } from '@/models/IPageOrderConfiguration'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IUserNavbarConfiguration } from '@/models/IUserNavbarConfiguration'

const resource = 'api/v1/user_configuration'
const space = 'pwa_sens'

export default {
    getUserNavbarConfigurationByConnectedUser(): Promise<AxiosResponse<IServerResponse<IUserNavbarConfiguration>>> {
        return axios.get(`${resource}/navbar`)
    },

    postUserNavbarConfiguration(
        navbarConfiguration: IUserNavbarConfiguration[]
    ): Promise<AxiosResponse<IServerResponse<IUserNavbarConfiguration>>> {
        return axios.post(`${resource}/navbar`, navbarConfiguration)
    },

    updateUserNavbarConfiguration(
        navbarConfiguration: IUserNavbarConfiguration[]
    ): Promise<AxiosResponse<IServerResponse<IUserNavbarConfiguration>>> {
        return axios.put(`${resource}/navbar`, { navbar_configuration: navbarConfiguration })
    },

    getPageOrderConfigurationByConnectedUser(typeRelatable: string, page: string): Promise<AxiosResponse<IServerResponse<IPageOrderConfiguration>>> {
        return axios.get(`${resource}/page_order/${space}/${typeRelatable}/${page}`)
    },

    postPageOrderConfiguration(
        typeRelatable: string, page: string, orderConfiguration: IOrderConfiguration
    ): Promise<AxiosResponse<IServerResponse<IPageOrderConfiguration>>> {
        return axios.post(`${resource}/page_order`, { space: space, type_relatable: typeRelatable, page: page, order_configuration: orderConfiguration })
    },

    updatePageOrderConfiguration(
        idPageOrderConfiguration: number,
        orderConfiguration: IOrderConfiguration
    ): Promise<AxiosResponse<IServerResponse<IPageOrderConfiguration>>> {
        return axios.put(`${resource}/page_order/${idPageOrderConfiguration}`, { order_configuration: orderConfiguration })
    },
}
