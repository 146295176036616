const state = () => ({
    token: '',
    type: 'web',
    platform: 'empty-shell',
    is_mobile: false,
})

const mutations = {
    isMobile(state): void {
        state.is_mobile = true
    },
    setToken(state, token: string): void {
        state.token = token
    },
    setType(state, type: string): void {
        state.type = type
    },
    setPlatform(state, platform: string): void {
        state.platform = platform
    },
}

const actions = {
    isMobile({ commit }, payload): void {
        commit('isMobile')
        commit('setToken', payload.token)
        commit('setType', payload.type)
        commit('setPlatform', payload.platform)
    },
}

const getters = {
    isMobile: (state): string => state.is_mobile,
    token: (state): string => state.token,
    type: (state): string => state.type,
    platform: (state): string => state.platform,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
