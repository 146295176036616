import UserConfigurationRepository from '@/repositories/UserConfigurationRepository'
import type { IPageOrderConfiguration } from '@/models/IPageOrderConfiguration'

const state = () => ({
    page_order_configuration_equipment: null
})

const mutations = {
    setPageOrderConfigurationEquipment(state, pageOrderConfigurationEquipment: IPageOrderConfiguration): void {
        state.page_order_configuration_equipment = pageOrderConfigurationEquipment
    }
}

const actions = {
    async getPageOrderConfigurationEquipment({ commit }): Promise<void> {
        await UserConfigurationRepository.getPageOrderConfigurationByConnectedUser('client', 'equipment').then((success) => {
            commit('setPageOrderConfigurationEquipment', success.data.data)
        })
    },

    async createPageOrderConfiguration({ commit }, payload): Promise<void> {
        await UserConfigurationRepository.postPageOrderConfiguration('client', payload.page, payload.order_configuration).then((success) => {
            if(success.data.data.page === 'equipment') {
                commit('setPageOrderConfigurationEquipment', success.data.data)
            }
        })
    },

    async updatePageOrderConfiguration({ commit }, payload): Promise<void> {
        await UserConfigurationRepository.updatePageOrderConfiguration(
            payload.id_page_order_configuration ,payload.order_configuration
        ).then((success) => {
            if(success.data.data.page === 'equipment') {
                commit('setPageOrderConfigurationEquipment', success.data.data)
            }
        })
    }
}

const getters = {
    page_order_configuration_equipment: (state) => state.page_order_configuration_equipment,
    order_configuration_equipment: (state) =>
        state.page_order_configuration_equipment ? state.page_order_configuration_equipment.configuration : null
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
