import axios from '@/plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IFile } from '@/models/IFile'

const resource = 'api/v1/file'

export default {
    getByIdFileAndModuleDesignation(idFile: number, moduleDesignation: string): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.get(`${resource}/${idFile}/module/${moduleDesignation}`, { responseType: 'blob' })
    },

    getByProfessionalId(moduleDesignation: string): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.get(`${resource}/professional/module/${moduleDesignation}`)
    },

    getByIdProfessionalAndIdClientAndModuleDesignation(
        idProfessional: number,
        moduleDesignation: string
    ): Promise<AxiosResponse<IServerResponse<IFile[]>>> {
        return axios.get(`${resource}/professional/${idProfessional}/client/module/${moduleDesignation}`)
    },

    postUploadFileForClientSpace(formData, moduleDesignation: string): Promise<AxiosResponse<IServerResponse<void>>> {
        return axios.post(`${resource}/client/module/${moduleDesignation}`, formData)
    },

    putEditFilename(idFile: number, moduleDesignation: string, filename: string): Promise<AxiosResponse<void>> {
        return axios.put(`${resource}/${idFile}/user/module/${moduleDesignation}`, { filename: filename })
    },

    deleteFiles(idFiles: number[], moduleDesignation: string): Promise<AxiosResponse<void>> {
        return axios.delete(`${resource}/module/${moduleDesignation}`, { data: { id_files: idFiles } })
    },
}
