import DisplayOptionRepository from '@/repositories/DisplayOptionRepository'
import type { IDisplayOption } from '@/models/IDisplayOption'

const state = () => ({
    display_option: {},
    logo: '/images/display_option/logo.png',
    background_login: '/images/display_option/bg_login.jpg',
    background_login_mobile: null,
})

const mutations = {
    setDisplayOption(state, displayOption: IDisplayOption): void {
        state.display_option = displayOption
        state.logo = displayOption.src_logo_light_v2
        state.background_login = displayOption.src_background_login
        state.background_login_mobile = displayOption.src_background_login_mobile
    },
}

const actions = {
    async getDisplayOptionByPwaSensUrl({ commit }): Promise<void> {
        await DisplayOptionRepository.getDisplayOptionByPwaSensUrl().then((success) => {
            commit('setDisplayOption', success.data.data)
        })
    },

    async getDisplayOptionByIdProfessional({ commit }, idProfessional: number): Promise<void> {
        await DisplayOptionRepository.getDisplayOptionByIdProfessional(idProfessional).then((success) => {
            commit('setDisplayOption', success.data.data)
        })
    },
}

const getters = {
    display_option: (state) => state.display_option,
    logo: (state) => state.logo,
    background_login: (state) => state.background_login,
    background_login_mobile: (state) => state.background_login_mobile,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
