import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js'
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js'
import axios from './axios'
import router from './router'

const auth = {
    plugins: {
        http: axios,
        router: router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios,
        router: driverRouterVueRouter,
    },
    options: {
        authRedirect: {
            path: '/login',
        },
        loginData: {
            url: '/auth/user_client',
            redirect: '',
        },
        fetchData: {
            url: '/auth/me',
            enabled: false,
        },
        refreshData: {
            enabled: false,
        },
    },
}

export default auth
