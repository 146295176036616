import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IReferential } from '@/models/IReferential'
import type { IRole } from '@/models/IRole'
import type { IAssetLiquid } from '@/models/IAssetLiquid'
import type { IModule } from '@/models/IModule'

const resource = 'api/v1/professional'

export default {
    getProfessionalAssetTypes(idProfessional: number): Promise<AxiosResponse<IServerResponse<IReferential[]>>> {
        return axios.get(`${resource}/${idProfessional}/asset_types`)
    },

    getClientRole(idProfessional: number): Promise<AxiosResponse<IServerResponse<IRole[]>>> {
        return axios.get(`${resource}/${idProfessional}/roles_client`)
    },

    getAssetLiquidsByProfessionalAndType(idProfessional: number, type: string): Promise<AxiosResponse<IServerResponse<IAssetLiquid[]>>> {
        return axios.get(`${resource}/${idProfessional}/asset_liquids/type/${type}`)
    },

    getProfessionalModules(): Promise<AxiosResponse<IServerResponse<Array<IModule>>>> {
        return axios.get(`${resource}/modules`)
    },

    getProfessionalModulesV2(): Promise<AxiosResponse<IServerResponse<Array<IModule>>>> {
        return axios.get(`api/v1/v2/professional/modules`)
    },

    getProfessionalModulesConfiguration(id_professional: number, id_module: number): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.get(`api/v1/v2/professional/${id_professional}/module/${id_module}/configuration`)
    },
}
