import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IAssetCompleteInformation } from '@/models/IAssetCompleteInformation'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IUserDatatable } from '@/models/IUserDatatable'
import type { IClient } from '@/models/IClient'
import type { IAssetCluster } from "@/models/IAssetCluster";

const resource = 'api/v1/client'

export default {
    getUserAssetsInformationByAssetTypeAndIdClient(
        idClient: number,
        idAssetType: number
    ): Promise<AxiosResponse<IServerResponse<IAssetCompleteInformation[]>>> {
        return axios.get(`${resource}/${idClient}/asset_type/${idAssetType}/assets`);
    },

    getClientAssetClusters(idClient: number): Promise<AxiosResponse<IServerResponse<IAssetCluster[]>>> {
        return axios.get(`${resource}/${idClient}/asset_cluster`);
    },

    getUserDatatable(idClient: number): Promise<AxiosResponse<IServerResponse<IUserDatatable[]>>> {
        return axios.get(`${resource}/${idClient}/users/datatable`);
    },

    getClientById(idClient: number): Promise<AxiosResponse<IServerResponse<IClient>>> {
        return axios.get(`${resource}/${idClient}`);
    },

    putEditUserRole(idClient: number, idUser: number, idRole: number): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.put(`${resource}/${idClient}/user/${idUser}/role`, { id_role: idRole })
    },

    updateClient(idClient: number, client): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.put(`${resource}/${idClient}`, client);
    },

    deleteUserAccess(idClient: number, data): Promise<AxiosResponse<IServerResponse<any>>> {
        return axios.delete(`${resource}/${idClient}/user/access`, { data: data });
    },
}
