import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IOrderCockpit } from '@/models/IOrderCockpit'
import type { IProduct } from '@/models/IProduct'
import type { IServerResponse } from '@/models/IServerResponse'

const resource = 'api/v1/order/cockpit'

export default {
    getOrderCockpitListByState(state): Promise<AxiosResponse<IServerResponse<IOrderCockpit[]>>> {
        return axios.get(`${resource}?state=${state}`)
    },

    getOrderCockpitListById(id: number): Promise<AxiosResponse<IServerResponse<IOrderCockpit>>> {
        return axios.get(`${resource}/${id}`)
    },

    getOrderCockpitProductsByIdAsset(idAsset: number): Promise<AxiosResponse<IServerResponse<IProduct[]>>> {
        return axios.get(`${resource}/products?id_asset=${idAsset}`)
    },

    validateOrderCockpit(id: number, order): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/${id}/validate`, order, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
    },

    deleteOrderCockpitById(order: IOrderCockpit): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.post(`${resource}/${order.id_order}`, order)
    },

    cancelOrderCockpit(id_order: number, data): Promise<AxiosResponse<IServerResponse<null>>> {
        return axios.put(`${resource}/${id_order}/cancel`, data)
    },
}
