import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import { IAssetLiquid } from '@/models/IAssetLiquid'

const resource = 'api/v1/asset_liquid'

export default {
    getAssetLiquidByIdModule(id_professional: number, id_asset_module: number): Promise<AxiosResponse<IServerResponse<Array<IAssetLiquid>>>> {
        return axios.get(`${resource}/professional/${id_professional}/module/${id_asset_module}`)
    },
}
