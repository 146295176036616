import axios from '../plugins/axios'
import type { AxiosResponse } from 'axios'
import type { IServerResponse } from '@/models/IServerResponse'
import type { IDomicile } from '@/models/IDomicile'

const resource = 'api/v1/user_client'

export default {
    getDomicilesByUserClient(id_client: number): Promise<AxiosResponse<IServerResponse<IDomicile[]>>> {
        return axios.get(`${resource}/${id_client}/domiciles`)
    },
}
