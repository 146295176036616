import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import asset_cluster from "@/store/modules/asset_cluster";
import asset_types from './modules/asset_types'
import display_option from './modules/display_option'
import clients from './modules/clients'
import domiciles from './modules/domiciles'
import general from './modules/general'
import manage_navbar from './modules/manage_navbar'
import notifications from './modules/notifications'
import orders_cockpit from '@/store/modules/orders_cockpit'
import page_order_configuration from './modules/page_order_configuration'
import snackbar from './modules/snackbar'
import tanks from './modules/tanks'
import user_connected from './modules/user_connected'
import user_navbar_configuration from './modules/user_navbar_configuration'
import pushy from '@/store/modules/pushy'
import alerts from '@/store/modules/alerts'
import asset_module from '@/store/modules/asset_module'

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {},
    mutations: {},
    modules: {
        asset_cluster,
        asset_types,
        clients,
        display_option,
        domiciles,
        general,
        manage_navbar,
        notifications,
        orders_cockpit,
        page_order_configuration,
        snackbar,
        tanks,
        user_connected,
        user_navbar_configuration,
        alerts,
        pushy,
        asset_module
    },
    plugins: [createPersistedState()],
})

export default store
