<template>
    <div id="pwa-sens-banner-module-alert-component">
        <v-expand-transition>
            <div v-if="banner_configuration && banner_configuration.message">
                <v-alert
                    :color="`#${banner_configuration.background_color}`"
                    class="font-14 mb-0"
                    close-icon="mdi-close"
                    density="comfortable"
                    dismissible
                    text
                >
                    <p
                        class="text-center"
                        v-html="formatBannerMessage(banner_configuration.message)"
                    />

                    <div v-if="showCountdown" class="d-flex align-center flex-wrap font-semibold justify-center gap-2 mt-2">
                        <p class="font-14 font-weight-bold text-uppercase">
                            {{ $t('pwa_sens_banner_module_alert_component.time_remaining_before_closing_interface') }}
                        </p>

                        <div class="d-flex gap-2">
                            <div v-for="cell in countdownCells" :key="cell.id" class="banner-countdown-cell w-24 font-12 rounded-md z-10" style="background: white !important">
                                <p :id="cell.id" class="font-16 font-weight-bold">00</p>
                                {{ cell.label }}
                            </div>
                        </div>
                    </div>
                </v-alert>
            </div>
        </v-expand-transition>
    </div>
</template>

<script>
import ProfessionalRepository from '@/repositories/ProfessionalRepository'
import { marked } from 'marked'
import { nextTick } from 'vue'

export default {
    name: 'PwaSensBannerModuleAlertComponent',
    methods: {
        fetchProfessionalModulesConfiguration() {
            if (this.userHasAccess('PWA_SENS_BANNER', this.role_vision)) {
                ProfessionalRepository.getProfessionalModulesConfiguration(
                    this.$auth.user().id_professional,
                    this.$auth.user().role_permissions.find((module) => module.designation_module === 'PWA_SENS_BANNER').id_module
                )
                    .then((success) => {
                        this.banner_configuration = success.data.data
                        if (this.banner_configuration.date_countdown) {
                            this.setupCountdown(new Date(this.banner_configuration.date_countdown))
                        }
                    })
                    .catch((error) => {
                        this.manageError(error)
                    })
            }
        },

        formatBannerMessage(text) {
            return marked.parse(text)
        },

        prettifyNumber(nb) {
            return nb < 10 ? `0${nb}` : nb.toString()
        },

        setupCountdown(date) {
            this.showCountdown = true
            nextTick(() => {
                this.objective = date
                this.countdown()
                clearInterval(this.interval)
                this.interval = setInterval(() => {
                    this.countdown()
                }, 1000)
            })
        },

        stopCountdown() {
            this.showCountdown = false
            clearInterval(this.interval)
        },

        countdown() {
            const days = document.getElementById('banner-countdown-days'),
                hours = document.getElementById('banner-countdown-hours'),
                minute = document.getElementById('banner-countdown-minutes'),
                second = document.getElementById('banner-countdown-seconds'),
                total_seconds = (this.objective - new Date()) / 1000

            if (total_seconds > 0) {
                let nb_days = Math.floor(total_seconds / (60 * 60 * 24))
                let nb_hours = Math.floor((total_seconds - nb_days * 60 * 60 * 24) / (60 * 60))
                let nb_minutes = Math.floor((total_seconds - (nb_days * 60 * 60 * 24 + nb_hours * 60 * 60)) / 60)
                let nb_seconds = Math.floor(total_seconds - (nb_days * 60 * 60 * 24 + nb_hours * 60 * 60 + nb_minutes * 60))

                days.textContent = this.prettifyNumber(nb_days)
                hours.textContent = this.prettifyNumber(nb_hours)
                minute.textContent = this.prettifyNumber(nb_minutes)
                second.textContent = this.prettifyNumber(nb_seconds)
            }
        },
    },
    data() {
        return {
            banner_configuration: {},

            objective: null,
            interval: null,
            showCountdown: false,
            countdownCells: [
                { label: this.$t('pwa_sens_banner_module_alert_component.days'), id: 'banner-countdown-days' },
                { label: this.$t('pwa_sens_banner_module_alert_component.hours'), id: 'banner-countdown-hours' },
                { label: this.$t('pwa_sens_banner_module_alert_component.minutes'), id: 'banner-countdown-minutes' },
                { label: this.$t('pwa_sens_banner_module_alert_component.seconds'), id: 'banner-countdown-seconds' },
            ],
        }
    },
    created() {
        this.$auth.load().then(async () => {
            this.fetchProfessionalModulesConfiguration()
        })
    },
    beforeDestroy() {
        this.stopCountdown()
    },
}
</script>

<style>
#pwa-sens-banner-module-alert-component p,
#pwa-sens-banner-module-alert-component span {
    color: inherit !important;
    margin: 0;
}

.banner-countdown-cell {
    width: 80px;
    text-align: center;
    background: white;
    z-index: 10;
    border-radius: 8px;
}
</style>
